import { errorMessage, successMessage } from 'components/BannerComponent'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useEffect, useState } from 'react'
import { GENERIC_FAIL_MESSAGE } from 'utils/constants'
import { useRouter } from 'next/router'

export default function Container ({ children }) {
  const router = useRouter()
  const state = useStoreState(s => ({
    me: s.me,
    requestOtpState: s.requestOtpState,
    otpActivationState: s.otpActivationState
  }))
  const actions = useStoreActions(a => ({
    setRequestOtpState: a.setRequestOtpState,
    getMe: a.getMe,
    setGetMe: a.setGetMe,
    requestOtp: a.requestOtp,
    setOtpActivationState: a.setOtpActivationState,
    otpActivation: a.otpActivation
  }))
  const [updateMobile, setUpdateMobile] = useState(false)
  const [otpValue, setOtpValue] = useState('')
  const [isResendCooldown, setIsResendCooldown] = useState(false)

  const handleActivateOtp = () => {
    if (typeof state.me.payload !== 'undefined' && state.me.payload.details !== null) {
      // const mobile = state.me.payload.details.temp_mobile === null ? state.me.payload.details.mobile : state.me.payload.temp_mobile
      actions.otpActivation({
        email: state.me.payload.details.email,
        otp: otpValue
      })
    }
  }

  const handleRequestOtp = () => {
    if (typeof state.me.payload !== 'undefined' && state.me.payload.details !== null) {
      setIsResendCooldown(true)
      setTimeout(() => {
        setIsResendCooldown(false)
      }, 10000)
      actions.requestOtp({
        email: state.me.payload.details.email,
        purpose: 'update_mobile'
      })
    }
  }

  useEffect(() => {
    const { status, payload } = state.otpActivationState
    if (status === 2) {
      successMessage(payload.details || 'Successfully verified mobile number')
      router.reload()
    } else if (status === 3) {
      const key = `${Object.keys(payload)[0]}`
      const message = payload[key]
      errorMessage(message || GENERIC_FAIL_MESSAGE, key)
      actions.setOtpActivationState({ status: 0, payload: null })
    }
  }, [state.otpActivationState])

  useEffect(() => {
    const { status, payload } = state.requestOtpState
    if (status === 2) {
      successMessage(payload.details || 'Successfully sent OTP')
    } else if (status === 3) {
      const key = `${Object.keys(payload)[0]}`
      const message = payload[key]
      errorMessage(message || GENERIC_FAIL_MESSAGE, key)
      actions.setRequestOtpState({ status: 0, payload: null })
    }
  }, [state.requestOtpState])

  return children({ state, setUpdateMobile, updateMobile, otpValue, setOtpValue, handleActivateOtp, handleRequestOtp, isResendCooldown })
}
