import { useState, useEffect } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import moment from 'moment-timezone'
import { IS_BONANZA_PROMO_ACTIVE } from 'utils/constants'

export default function useBonanzaPrompt() {
  const state = useStoreState((s) => ({
    latestBonanzaPrompt: s.latestBonanzaPrompt,
    updatedBonanzaPrompt: s.updatedBonanzaPrompt,
    me: s.me
  }))
  const actions = useStoreActions((a) => ({
    setLatestBonanzaPrompt: a.setLatestBonanzaPrompt,
    getLatestBonanzaPrompt: a.getLatestBonanzaPrompt,
    putUpdateBonanzaPrompt: a.putUpdateBonanzaPrompt,
    setUpdatedBonanzaPrompt: a.setUpdatedBonanzaPrompt
  }))

  const [showBonanzaPrompt, setShowBonanzaPrompt] = useState(false)

  useEffect(() => {
    const { status, payload } = state.me

    if (status === 2 && payload.details && IS_BONANZA_PROMO_ACTIVE === 1) {
      actions.getLatestBonanzaPrompt()
    }
  }, [state.me])

  useEffect(() => {
    const { status, payload } = state.latestBonanzaPrompt

    if (status === 2 && typeof payload.last_shown_tz !== 'undefined') {
      const today = moment().format('YYYY-MM-DD hh:mm')
      const lastAccepted = moment(payload.last_shown).format('YYYY-MM-DD hh:mm')
      const dayDiff = moment(today).diff(lastAccepted, 'days')

      if (dayDiff > 0) {
        setShowBonanzaPrompt(true)
      }
    } else if (status === 3 && typeof payload !== 'undefined') {
      setShowBonanzaPrompt(true)
    }
  }, [state.latestBonanzaPrompt])

  useEffect(() => {
    const { status } = state.updatedBonanzaPrompt

    if (status === 2) {
      setShowBonanzaPrompt(false)
    }
  }, [state.updatedBonanzaPrompt])

  const closeBonanzaPrompt = () => {
    actions.putUpdateBonanzaPrompt()
  }

  return { showBonanzaPrompt, closeBonanzaPrompt }
}
