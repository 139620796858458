import styled from 'styled-components'
import { Row } from 'antd'

export const StyledMessageWrapper = styled(Row)`
  display: flex;
  flex-direction: row;
  h3 {
    font-family: MessengerTexture;
  }
  .ant-btn {
    position: absolute;
    top: 0;
    right: 0;
    float: right;
  }
  .ant-btn-text:hover {
    background-color: transparent;
    svg {
      color: var(--orange-peel);
    }
  }

  @media only screen and (max-width: 768px) {
    width: 300px !important;
  }
`
