import { Button, message } from 'antd'
import {
  IoCheckmarkCircleSharp,
  IoClose,
  IoInformationCircleSharp,
  IoWarning
} from 'react-icons/io5'
import { StyledMessageWrapper } from './styles'

const content = (title, contents) => (
  <StyledMessageWrapper>
    <div style={{ marginLeft: 15 }}>
      <h3>{title}</h3>
      <p>{contents}</p>
    </div>
    <Button
      shape='circle'
      type='text'
      icon={<IoClose style={{ color: 'var(--orange-peel)', fontWeight: 'bold', fontSize: 34 }} />}
      onClick={() => message.destroy(contents)}
    />
  </StyledMessageWrapper>
)

export const errorMessage = (contents, title) => {
  const errorTitle = title || 'OOPS'
  message.error({
    icon: <IoWarning style={{ fontSize: 28, color: 'var(--red)' }} />,
    content: content(errorTitle, contents),
    className: 'error-banner',
    key: contents
  })
}

export const successMessage = (contents, title) => {
  const successTitle = title || 'SUCCESS'
  message.success({
    icon: <IoCheckmarkCircleSharp style={{ fontSize: 28, color: 'var(--green)' }} />,
    content: content(successTitle, contents),
    className: 'success-banner',
    key: contents
  })
}

export const infoMessage = (contents, title) => {
  const infoTitle = title || 'NOTICE'
  message.info({
    icon: <IoInformationCircleSharp style={{ fontSize: 28, color: '#F8A023' }} />,
    content: content(infoTitle, contents),
    className: 'info-banner',
    key: contents
  })
}
