import ViewContainer from './container'
import { DefaultModal } from 'components/Modals'
import { Row, Col, Form, Image, Input, Button } from 'antd'
import styled from 'styled-components'
import FormItem from 'components/FormItem'
import { LoadingOutlined } from '@ant-design/icons'
import ActivateOTPModal from '../ActivateOTPModal'

const StyledUpdateMobileWrapper = styled(Row)`
    padding: 25px;
  .ant-col {
    text-align: center;
    h1 {
      color: var(--cognac);
    }
    h2, h3 {
      font-family: KreonRegular;
    }
    span {
      color: var(--grey);
    }
    .ant-btn-primary {
      justify-content: center;
      span {
        color: var(--white);
      }
    }
    .ant-btn-link {
      margin-bottom: 25px;
      span {
        color: var(--orange-peel);
      }
    }
  }
`

export default function Index ({ ...rest }) {
  return (
    <ViewContainer {...rest}>
      {({ state, form, handleUpdateMobile, openVerifyOtp, setOpenVerifyOtp, mobileToActivate }) => (
        <>
          {/* otp verification modal */}
          <ActivateOTPModal mobileToActivate={mobileToActivate} visible={openVerifyOtp} footer={null} setVisible={setOpenVerifyOtp} closable={true} destroyOnClose={true} />
          {(state.me.status === 2 && state.me.payload !== null) && (
            <DefaultModal {...rest}>
              <StyledUpdateMobileWrapper justify='center' align='middle' gutter={[8, 32]}>
                <Col span={24}>
                  <h1>Update your mobile number</h1>
                  <Image preview={false} src='/images/auth-assets/dash.png' />
                </Col>
                <Col span={18} style={{ marginTop: 30 }}>
                  <Form form={form} onFinish={handleUpdateMobile} preserve={false}>
                    <FormItem
                        form={form}
                        label='Mobile Number'
                        name='mobile_number'
                        rules={[
                          { required: true, message: 'Mobile number is required.' },
                          { pattern: /^[0-9]*$/, message: 'Mobile number must be a valid number' }
                        ]}
                    >
                      {props => (<Input prefix='+63' {...props} />)}
                    </FormItem>
                    <Button disabled={state.updateMeState.status === 1 || state.verifyOtpState.status === 1} block htmlType='submit' type='primary'>
                      Update mobile number {(state.updateMeState.status === 1 || state.verifyOtpState.status === 1) && <LoadingOutlined />}
                    </Button>
                  </Form>
                </Col>
              </StyledUpdateMobileWrapper>
            </DefaultModal>
          )}
        </>
      )}
    </ViewContainer>
  )
}
