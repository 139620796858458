import { Space } from 'antd'
import styled from 'styled-components'

export const StyledH1 = styled.h1``
export const StyledH2 = styled.h2`
  color: #92341B;
`
export const StyledDisclaimer = styled(Space)`
  padding: 10px;
  border: 1px solid #B5B5B5;
  text-align: center;
  background: #92341B;
  color: #FFEFD1;
  border-radius: 25px;
  display: block;
  font-size: 14px;
`
export const Styled = styled.div`
  .height-100 {
    height: 100%;
  }
  .img-absolute {
    position: absolute;
  }
  

  @media (max-width: 411px) {
    .mp-footer-container .mp-btn a button {
      width: 100px;
    }
  }

  @media (max-width: 360px) {
    .mp-footer-container .mp-btn a button {
      width: 85px;
    }
  }

  @media (max-width: 280px) {
    .mp-footer-container {
      padding-bottom: 20px;
      .mp-btn {
        a button {
          width: 65px;
          span {
            font-size: 12px;
          }
        }
      }
    }
    .p-btn a button span {
      font-size: 8px;
    }
    .p-price span {
      font-size: 14px;
    }
  }
`
