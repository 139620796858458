import CatalogLayout from 'layout/Catalog'
import HomeBaseComponent from 'containers/HomeBaseComponent'
import { clientTokenHandler } from 'utils/oauth'

export async function getServerSideProps ({ req, res }) {
  const cToken = await clientTokenHandler(req, res)

  if (!cToken) {
    return {
      redirect: {
        permanent: false,
        destination: '/503'
      }
    }
  }

  return {
    props: {
      error: !cToken
    }
  }
}

export default function Index (props) {
  return (
    <CatalogLayout>
      {() => <HomeBaseComponent {...props} />}
    </CatalogLayout>
  )
}
