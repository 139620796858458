import { useStoreState, useStoreActions } from 'easy-peasy'
import { useEffect, useState } from 'react'
import { Form } from 'antd'
import { errorMessage, successMessage } from 'components/BannerComponent'
import { GENERIC_FAIL_MESSAGE } from 'utils/constants'

export default function Container ({ children, ...rest }) {
  const state = useStoreState(s => ({
    me: s.me,
    updateMeState: s.updateMeState,
    requestOtpState: s.requestOtpState,
    verifyOtpState: s.verifyOtpState
  }))
  const actions = useStoreActions(a => ({
    setRequestOtpState: a.setRequestOtpState,
    requestOtp: a.requestOtp,
    updateMe: a.updateMe,
    setUpdateMeState: a.setUpdateMeState,
    getMe: a.getMe
  }))

  const [form] = Form.useForm()
  const [openVerifyOtp, setOpenVerifyOtp] = useState(false)
  const [mobileToActivate, setMobileToActivate] = useState('')

  const handleUpdateMobile = (values) => {
    if (typeof values.mobile_number !== 'undefined' && values.mobile_number) {
      values.mobile_number = '0' + String(Number(values.mobile_number))
    }
    actions.updateMe({
      first_name: state.me.payload.details.first_name,
      last_name: state.me.payload.details.last_name,
      email: state.me.payload.details.email,
      mobile: values.mobile_number,
      username: state.me.payload.details.email
    })
  }

  useEffect(() => {
    const { status, payload } = state.updateMeState
    if (status === 2) {
      if (!payload.is_verified_mobile) {
        actions.requestOtp({
          email: payload.email,
          purpose: 'update_mobile'
        })
        setOpenVerifyOtp(true)
        setMobileToActivate(payload.mobile)
        rest.setVisible(false)
      }
      actions.getMe()
      actions.setUpdateMeState({ status: 0, payload: null })
    } else if (status === 3) {
      const key = `${Object.keys(payload)[0]}`
      const message = payload[key]
      errorMessage(message || GENERIC_FAIL_MESSAGE, key)
      actions.setUpdateMeState({ status: 0, payload: null })
    }
  }, [state.updateMeState])

  useEffect(() => {
    const { status, payload } = state.requestOtpState
    if (status === 2) {
      successMessage(payload.details)
      setTimeout(() => {
        setOpenVerifyOtp(true)
      }, 1000)
    } else if (status === 3) {
      const key = `${Object.keys(payload)[0]}`
      const message = payload[key]
      errorMessage(message || GENERIC_FAIL_MESSAGE, key)
      actions.setRequestOtpState({ status: 0, payload: null })
    }
  }, [state.requestOtpState])

  return children({ state, form, handleUpdateMobile, openVerifyOtp, setOpenVerifyOtp, mobileToActivate })
}
