import { useStoreActions, useStoreState } from 'easy-peasy'
import { useState, useEffect } from 'react'

export default function Container ({ children, ...rest }) {
  const state = useStoreState(s => ({
    me: s.me,
    requestOtpState: s.requestOtpState,
    otpActivationState: s.otpActivationState,
    updateMeState: s.updateMeState
  }))
  const actions = useStoreActions(a => ({
    requestOtp: a.requestOtp,
    setRequestOtpState: a.setRequestOtpState
  }))

  const [openVerifyOtp, setOpenVerifyOtp] = useState(false)
  const [updateMobile, setUpdateMobile] = useState(false)

  const handleRequestOtp = () => {
    if (typeof state.me.payload !== 'undefined' && state.me.payload.details !== null) {
      actions.requestOtp({
        email: state.me.payload.details.email
      })
    }
  }

  const handleSkipForNow = () => {
    localStorage.setItem('skipMobileActivation', '1')
    // came from modal props
    rest.setVisible(false)
  }

  useEffect(() => {
    const { status } = state.updateMeState
    if (status === 2) {
      setUpdateMobile(false)
    }
  }, [state.updateMeState])

  return children({ state, setUpdateMobile, updateMobile, openVerifyOtp, setOpenVerifyOtp, handleRequestOtp, handleSkipForNow })
}
