import React, { useState } from 'react'
import { StyledFormItemWrapper } from './styles'
import PropTypes from 'prop-types'

export default function Index ({
  children,
  label = '',
  name = '',
  form,
  disabled = false,
  hasFeedback = false,
  isdefaultheight = true,
  rules = [],
  required = false,
  numberOnly = false,
  ...rest
}) {
  const [focus, setFocus] = useState(false)
  const [value, setValue] = useState(form.getFieldValue(name))

  let childComponent = null

  if (numberOnly) {
    childComponent = children({
      name,
      onChange: (event) => {
        if (typeof event.target !== 'undefined') {
          const reg = /^-?\d*(\.\d*)?$/

          if ((!isNaN(event.target.value) && reg.test(event.target.value)) || event.target.value === '' || event.target.value === '-') {
            form.setFieldsValue({
              [name]: event.target.value
            })
            form.validateFields([name])
            setValue(event.target.value)
          }
        } else {
          form.setFieldsValue({
            [name]: event
          })
          form.validateFields([name])
        }
      },
      onBlur: () => {
        setFocus(false)
      },
      onFocus: () => {
        setFocus(true)
      },
      disabled: disabled,
      defaultValue: form.getFieldValue(name),
      autoComplete: 'off',
      value: value
    })
  } else {
    childComponent = children({
      name,
      onChange: (event) => {
        if (typeof event.target !== 'undefined') {
          form.setFieldsValue({
            [name]: event.target.value
          })
          form.validateFields([name])
        } else {
          form.setFieldsValue({
            [name]: event
          })
          form.validateFields([name])
        }
      },
      onBlur: () => {
        setFocus(false)
      },
      onFocus: () => {
        setFocus(true)
      },
      disabled: disabled,
      defaultValue: form.getFieldValue(name),
      autoComplete: 'off'
    })
  }

  // we make label float when it has prefix
  const hasPrefix = childComponent.props.prefix

  return (
    <StyledFormItemWrapper
      name={name}
      rules={rules}
      hasFeedback={hasFeedback}
      labellength={label.length}
      isdefaultheight={isdefaultheight}
      className='float-label'
      {...rest}
    >
      {childComponent}
      <label className={focus || form.getFieldValue(name) || hasPrefix ? 'label label-float' : 'label'}>
        {label}
        {required && '*'}
      </label>
    </StyledFormItemWrapper>
  )
}

Index.propTypes = {
  children: PropTypes.any,
  label: PropTypes.string,
  name: PropTypes.string,
  form: PropTypes.any,
  disabled: PropTypes.bool,
  hasFeedback: PropTypes.bool,
  isdefaultheight: PropTypes.bool,
  rules: PropTypes.array,
  required: PropTypes.bool,
  numberOnly: PropTypes.bool
}
