// Supercard Benefits
export const benefits = (status = '') => ({
  'SC20%': {
    code: 'SC20%',
    link: '/supercard',
    bgColor: {
      rgba1: '255,176,1,0.9',
      rgba2: '255,176,1,0.9'
    },
    rightImg: { path: '/images/sc-fb-pizza-wsupercard.png' },
    text: {
      title: 'FREE Welcome Pizza!',
      color: 'black',
      description: "You may visit any Shakey's store to claim your Welcome Pizza.\nJust present your Supercard or this app!",
      descriptionColor: 'black'
    },
    status: 'Unavailable',
    btnText: 'Visit our store'
  },
  BTL: {
    code: 'BTL',
    link: '/supercard',
    bgColor: {
      rgba1: '39,39,39,255',
      rgba2: '39,39,39,0.9'
    },
    rightImg: { path: '/images/sc-fb-pizza.png' },
    text: {
      title: 'FREE Shakey’s Birthday Pizza',
      color: 'white',
      description: "Claim your FREE birthday pizza at any Shakey's Store, or add your FREE Thin Crust, Large Pepperoni Pizza to your cart. For delivery, minimum value of P300 will apply.",
      descriptionColor: 'white'
    },
    status,
    btnText: 'Add to cart'
  },
  'SC15%': {
    code: 'SC15%',
    link: '/supercard',
    bgColor: {
      rgba1: '255,176,1,0.9',
      rgba2: '255,176,1,0.9'
    },
    rightImg: { path: '/images/sc-fb-pizza-wsupercard.png' },
    text: {
      title: 'Shakey’s Birthday Discount 15%',
      color: 'black',
      description: "Visit your nearest Shakey's store and present your Supercard or this app.",
      descriptionColor: 'black'
    },
    status: 'Unavailable',
    btnText: 'Visit our store'
  },
  // 'CBOL-WP': {
  //   code: 'CBOL-WP',
  //   link: '/supercard',
  //   bgColor: {
  //     rgba1: '255,176,1,0.9',
  //     rgba2: '255,176,1,0.9'
  //   },
  //   rightImg: { path: '/images/sc-fb-pizza-wsupercard.png' },
  //   text: {
  //     title: '',
  //     color: 'black',
  //     description: '',
  //     descriptionColor: 'black'
  //   },
  //   status,
  //   btnText: 'Visit our store'
  // },
  'QTR-PBT': {
    code: 'QTR-PBT',
    link: '/supercard',
    bgColor: {
      rgba1: '255,255,255,0.8',
      rgba2: '255,255,255,0.9'
    },
    rightImg: { path: '/images/sc-fb-periperi-wsupercard.png' },
    text: {
      title: 'FREE Peri-Peri Birthday Chicken',
      color: 'black',
      description: 'Claim a FREE Peri-Peri Quarter Chicken Meal with rice and one (1) side by visiting any Peri-Peri Charcoal Chicken and Sauce Bar. Minimum purchase of PHP 300.'
    },
    status: 'Unavailable',
    btnText: 'Visit our store'
  }
})

export const isFreebies = (code) => {
  const list = ['SC20%', 'BTL', 'SC15%', 'CBOL-WP', 'QTR-PBT']
  return list.includes(code)
}
