import { useStoreState, useStoreActions } from 'easy-peasy'
import { useEffect, useState } from 'react'
import { hasSuperCard } from 'utils/user'
import useBonanzaPrompt from 'hooks/useBonanzaPrompt'

export default function Container ({ children }) {
  const [featuredProducts, setFeaturedProducts] = useState([])
  const [me, setMe] = useState(null)
  const [verifyMobile, setVerifyMobile] = useState(false)

  const { showBonanzaPrompt, closeBonanzaPrompt } = useBonanzaPrompt()

  const state = useStoreState(s => ({
    me: s.me,
    featuredProducts: s.featuredProducts
  }))

  const actions = useStoreActions(a => ({
    getFeaturedProducts: a.getFeaturedProducts,
    setFeaturedProducts: a.setFeaturedProducts
  }))

  useEffect(() => {
    if (!hasSuperCard()) {
      actions.getFeaturedProducts({ is_supercard_exclusive: false })
    } else {
      actions.getFeaturedProducts()
    }

    return () => {
      actions.setFeaturedProducts({ status: 0, payload: null })
      setFeaturedProducts([])
    }
  }, [])

  useEffect(() => {
    const { status, payload } = state.me
    if (status === 2 && payload.details) {
      setMe(payload.details)
      if (localStorage.getItem('skipMobileActivation') === null) {
        setVerifyMobile(!payload.details.is_verified_mobile)
      }
    } else {
      setMe(null)
    }
  }, [state.me])

  useEffect(() => {
    const { status, payload } = state.featuredProducts
    if (status === 2 && payload?.results?.length) {
      setFeaturedProducts(payload.results.slice(0, 5))
    }
  }, [state.featuredProducts])

  return children({
    me,
    state,
    featuredProducts,
    verifyMobile,
    setVerifyMobile,
    showBonanzaPrompt,
    closeBonanzaPrompt
  })
}
