import { LoadingOutlined } from '@ant-design/icons'
import { Button, Col, Image, Row, Space } from 'antd'
import { DefaultModal } from 'components/Modals'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { formatMobileNumber } from 'utils/helpers'
import UpdateMobileModal from '../UpdateMobile'
import ViewContainer from './container'

const StyledMobileConfirmationWrapper = styled(Row)`
    padding: 25px;
  .ant-col {
    text-align: center;
    h1 {
      color: var(--cognac);
    }
    h2, h3 {
      font-family: KreonRegular;
    }
    span {
      color: var(--grey);
    }
    .ant-btn-primary {
      justify-content: center;
      span {
        color: var(--white);
      }
    }
    .ant-btn-link {
      margin-bottom: 25px;
      span {
        color: var(--orange-peel);
      }
    }
  }
`

export default function Index ({ accountDetails = {}, ...rest }) {
  return (
    <ViewContainer {...rest}>
      {({ state, setUpdateMobile, updateMobile, handleRequestOtp, handleSkipForNow }) => (
        <>

          {(state.me.payload !== null && state.me.payload.details !== null) && (
            <>
              <DefaultModal {...rest}>
                <StyledMobileConfirmationWrapper justify='center' align='middle' gutter={[8, 32]}>
                  <Col span={24}>
                    <h1>Account Confirmation</h1>
                    <Image preview={false} src='/images/auth-assets/dash.png' />
                  </Col>
                  <Col span={24}>
                    <h2>{state.me.payload.details.first_name + ' ' + state.me.payload.details.last_name}</h2>
                    <span>{state.me.payload.details.email}</span>
                  </Col>
                  <Col span={24}>
                    <h3>Is this your number?</h3>
                    <p>{formatMobileNumber(state.me.payload.details.mobile)}</p>
                  </Col>
                  <Col span={18}>
                    <Space direction='vertical' style={{ width: '100%' }}>
                      <Button type='link' block onClick={() => setUpdateMobile(true)}>Change my number</Button>
                      <Button type='primary' block onClick={() => handleRequestOtp()}>My number is correct {state.requestOtpState.status === 1 && <LoadingOutlined />}</Button>
                      <Button className='cognac-btn' block onClick={() => handleSkipForNow()}>Skip for now</Button>
                    </Space>
                  </Col>
                </StyledMobileConfirmationWrapper>
              </DefaultModal>
            </>
          )}

          {/* update mobile modal */}
          <UpdateMobileModal visible={updateMobile} footer={null} setVisible={setUpdateMobile} closable={true} destroyOnClose={true} />
        </>
      )}
    </ViewContainer>
  )
}

Index.propTypes = {
  accountDetails: PropTypes.object
}
