import { Modal, Row, Col, Button } from 'antd'
import styled from 'styled-components'
import { BONANZA_ENROLLMENT_URL } from 'utils/constants'
import PropTypes from 'prop-types'

const StyledModal = styled(Modal)`
    padding: 0;
    .ant-modal-body, .ant-modal-content {
        background-image: url('/images/supercard_v2/bonanza-prompt-bg.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 35px;
        padding: 0;
        position: relative;
        overflow: ;
    }
    .ant-modal-close-x {
        color: #fff;
    }
    .header-wrapper {
      padding-top: 20px;
      display: flex;
      flex-direction: column;
      justify-items: center;
      align-items: center;
      overflow: visible;
      text-align: center;
      .cars {
        background-image: url('/images/supercard_v2/bmw.png');
        background-position: center;
        background-size: cover;
        height: 420px;
        width: 695px;
        position: static;
      }
    }
    .brands {
      width: 40%;
    }

    p {
        color: #fff;
        font-size: 16px;
    }

    .ant-row {
      padding: 24px;
      text-align: center;
    }

    .ant-btn {
      background: #fff;
      color: #bb0706;
      font-weight: bold;
      font-size: 14px; !important;
      margin: 0 auto;
    }

    @media (max-width: 375px) {
      .logo {
        width: 232px;
      }
      .brands {
        width: 70%;
      }
    
      .header-wrapper {
        .cars {
          width: 365px;
          height: 220px;
        }
      }
    }

    @media (min-width: 376px)  and (max-width: 576px) {
      width: 325px !important;
      .logo {
        width: 80%;
      }
      .brands {
        width: 70%;
      }
      .header-wrapper {
        .cars {
          width: 460px;
          height: 320px;
        }
      }
    }
`

export default function BonanzaPrompt ({ visible, closeModal }) {
  return (
    <StyledModal centered visible={visible} footer={null} width={509} onCancel={closeModal} maskClosable={false}>
      <div className='header-wrapper'>
        <img
          className='logo'
          src='/images/supercard_v2/bonanza-logo.png'
          width={347}
          height={195}
          alt='Bonanza Web'
          // preview={false}
        />
        <div className='cars' />
      </div>
      <Row justify='center' align='middle' gutter={[0, 14]}>
        <Col span={24}>
          <img
            className='brands'
            src='/images/supercard_v2/prompt-brands-v2.png'
            alt='brands'
          />
        </Col>
        <Col span={24}>
          <p>
            Keep using your active Supercard for a chance to win brand new BMW!
            Every P300 spent = 1 raffle entry
          </p>
        </Col>
        <Col span={24}>
          <Button className='bonanza-btn' onClick={() => {
            closeModal()
            window.location.href = BONANZA_ENROLLMENT_URL
          }} type='primary'>Enroll in Raffle Now!</Button>
        </Col>
      </Row>
    </StyledModal>
  )
}

BonanzaPrompt.propTypes = {
  visible: PropTypes.bool,
  closeModal: PropTypes.func
}
