import moment from 'moment'
import { SAFARI } from 'utils/regex'
import { isFreebies } from './sc_benefits'
import { MAX_B1T1 } from './constants'

export const formatMobileNumber = (mobileInfo) => {
  if (typeof mobileInfo !== 'undefined' && mobileInfo) {
    if (typeof mobileInfo === 'object') {
      const { prefix, number } = mobileInfo

      if (prefix === '0') {
        return `+63 ${number}`
      } else {
        return `${prefix} ${number}`
      }
    } else {
      if (mobileInfo.charAt(0) === '+') {
        return mobileInfo.replace('+63', '0')
      } else {
        return mobileInfo
      }
    }
  }
}

export const mobileInitialValue = (mobile) => {
  if (mobile) {
    if (mobile.charAt(0) === '+') {
      return mobile.substring(3)
    } else if (mobile.charAt(0) === '0') {
      return mobile.substring(0)
    }
  }
}

export const sortByCategory = (a, b) => {
  if (
    a.product_data.category_sort_order[0].sort_order <
    b.product_data.category_sort_order[0].sort_order
  ) {
    return -1
  }
  if (
    a.product_data.category_sort_order[0].sort_order >
    b.product_data.category_sort_order[0].sort_order
  ) {
    return 1
  }
  return 0
}

export const sortAlphabetically = (product) => {
  const sorted = product.sort((a, b) => {
    if (a.product_data.name < b.product_data.name) {
      return -1
    }
    if (a.product_data.name > b.product_data.name) {
      return 1
    }
    return 0
  })

  return sorted
}

export const displayComposition = (options = []) => {
  if (options.length > 0) {
    const composition = options.map((option) => {
      return option.option_values.map((value) => {
        if (
          typeof value.option_value_quantity !== 'undefined' &&
          value.option_value_quantity > 1
        ) {
          return `${value.option_value_quantity}x ${value.name}`
        }

        return value.name
      })
    })

    return composition.join(', ')
  }
}

export const selectSortAlphabetically = (optionA, optionB) =>
  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())

export const onMobile = () => {
  if (typeof window !== 'undefined') {
    return !!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  }
}

export const formatDeliveryAddress = (address) => {
  if (typeof address === 'object' && Object.keys(address).length) {
    return `${address.house_number} ${address.building} ${address.street}, ${address.barangay}`
  } else {
    return address
  }
}

export const getLastExpirySc = (supercards = []) => {
  if (supercards.length > 1) return moment(Math.max(...supercards.map(card => new Date(card.ExpirationDate)))).format('MMMM DD, YYYY')

  return moment(supercards[0].ExpirationDate).format('MMMM DD, YYYY')
}

export const forceOpenTab = (url) => {
  if (typeof url !== 'undefined' && url) {
    const mobileSafari = Boolean(SAFARI.test(navigator.userAgent) && window.innerWidth < 768)
    /**
     * Created this condition because we cannot simulate click event in mobile safari,
     * and the new tab is blocked.
     */
    if (mobileSafari) {
      window.open(url, '_self')
    } else {
      const opener = document.createElement('a')
      opener.target = '_blank'
      opener.href = url
      opener.click()
      opener.remove()
    }
  }
}

export const hasFreebieInItems = (items, product) => {
  if (items && product) {
    return items.filter(
      (x) =>
        x.product_details &&
        isFreebies(x.product_details.sku) === true &&
        x.product_details.sku === product.product_data.sku
    ).length > 0
  }

  return false
}

export const isItemB1t1 = (item) => {
  return (
    item?.product_data?.is_buy1_take1 ||
    item?.product_details?.is_buy1_take1 ||
    false
  )
}

export const isB1t1Exceeded = (b1t1Count = 0, itemQuantity = 1) => Boolean(b1t1Count > 0 && (itemQuantity + b1t1Count) > MAX_B1T1)

export const displaySelectedPayment = (paymentCode) => {
  if (typeof window !== 'undefined') {
    const payments = JSON.parse(sessionStorage.getItem('sessionPayments')) || null

    if (payments) {
      return payments.filter(payment => payment.code === paymentCode)[0]?.name || ''
    }
  }
}
