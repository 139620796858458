import ViewContainer from './container'
import { Row, Col, Button } from 'antd'
import { StyledOtpInput } from 'components/GlobalStyles'
import { LoadingOutlined } from '@ant-design/icons'
import { DefaultModal } from 'components/Modals'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledVerifyOtpWrapper = styled(Row)`
    padding: 25px;
  .ant-col {
    text-align: center;
    h1 {
      color: var(--cognac);
    }
    h2, h3 {
      font-family: KreonRegular;
    }
    span {
      color: var(--grey);
    }
    .ant-btn-primary, .ant-btn-text {
      justify-content: center;
    }
    .ant-btn-primary {
      span {
        color: var(--white);
      }
    }
    .ant-btn-text {
      margin-bottom: 25px;
      span {
        color: var(--orange-peel);
      }
      &:hover, &:disabled{
        background: inherit;
      }
    }
  }
`

export default function VerifyOTP ({ mobileToActivate = '', ...rest }) {
  return (
    <ViewContainer>
      {({ state, otpValue, setOtpValue, handleActivateOtp, isResendCooldown, handleRequestOtp }) => (
        <>
          {(state.me.status === 2 && state.me.payload.details !== null) && (
            <DefaultModal {...rest} onCancel={() => { setOtpValue(''); rest.setVisible(false) }}>
              <StyledVerifyOtpWrapper className='body-wrapper'>
                <Col span={24}>
                  <Row className='mode'>
                    <Col span={24}>
                      <h1>Mobile Verification</h1>
                      <img
                      style={{ marginBottom: 38 }}
                      src='/images/auth-assets/dash.png'
                      alt=''
                    />
                    </Col>
                  </Row>
                  <Row className='mode'>
                    <Col span={24}>
                      <p>
                        You will receive a One-Time-PIN ( OTP ) on your registered
                        mobile number ending in
                        {mobileToActivate !== '' ? ` ${mobileToActivate?.substr(mobileToActivate.length - 4)}.` : ` ${state.me.payload.details?.mobile?.substr(state.me.payload.details.mobile.length - 4)}`}
                      </p>
                    </Col>
                  </Row>
                  <Row className='otp-input-wrapper' justify='center' align='middle'>
                    <StyledOtpInput
                    value={otpValue}
                    onChange={(otp) => setOtpValue(otp)}
                    numInputs={6}
                    isInputNum={true}
                    inputStyle={{ outline: 'none' }}
                  />
                  </Row>
                  <Row className='btn-wrapper' justify='center' align='middle' style={{ marginTop: 25 }}>
                    <Col span={16}>
                      <Button onClick={() => handleActivateOtp()} disabled={state.otpActivationState.status === 1 || otpValue.length < 6} type='primary' block>
                        Verify Otp {state.otpActivationState.status === 1 && <LoadingOutlined />}
                      </Button>
                      <Button onClick={() => handleRequestOtp()} disabled={state.requestOtpState.status === 1 || isResendCooldown} type='text' block>
                        Resend Code {(state.requestOtpState.status === 1 || isResendCooldown) && <LoadingOutlined />}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </StyledVerifyOtpWrapper>
            </DefaultModal>
          )}
        </>
      )}
    </ViewContainer>
  )
}

VerifyOTP.propTypes = {
  mobileToActivate: PropTypes.string
}
